<template>
  <b-row>
    <b-modal id="rocModal" title="Remove Enterprise" hide-footer no-close-on-backdrop>
      <rocModal @refresh="getAllResons"/>
    </b-modal>
    <b-col lg='12'>
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">reason</h4>
        </template>
        <template v-slot:headerAction>
          <b-button v-b-modal.rocModal variant="primary">Add New Reason</b-button>
        </template>
        <template v-slot:body>
          <div v-if="loadTable" class="text-center">
            <b-spinner small type="grow"></b-spinner>
            Loading...
          </div>
          <b-table v-else striped responsive :fields="columns" :items="allReasons" class="mb-0 table-borderless">
<!--            <template v-slot:cell(is_active)="data3">
              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" >
                <div class="custom-switch-inner">
                  <input type="checkbox" class="custom-control-input bg-primary" v-model="data3.item.is_active" :id="'customSwitch-11'+data3.item.id"  @change="switchWhatsappStatus(data3.item.id)" >
                  <label class="custom-control-label" :for="'customSwitch-11'+data3.item.id" data-on-label="on" data-off-label="off">
                  </label>
                </div>
              </div>
            </template>
            <template v-slot:cell(actions)="data1">
              <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" :class="'input_'+data1.item.id"><i class="ri-ball-pen-fill m-0"></i></b-button>
            </template>-->
          </b-table>
          <b-pagination v-model="currentPage"
                        class="mt-3"
                        :total-rows="total"
                        align="right"
                        :per-page="per_page"
                        aria-controls="my-table"
                        @input="getAllResons"
          ></b-pagination>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>
<script>
import { core } from '../../config/pluginInit'
import settingService from './settingService'
import rocModal from '../../components/roc/reason'
export default {
  name: 'settingsRoc',
  data () {
    return {
      currentPage: 1,
      per_page: 10,
      total: 6,
      loadTable: true,
      columns: [
        { label: 'Reason', key: 'reason', class: 'text-left' },
        { label: 'Group', key: 'group', class: 'text-left' }
      ],
      allReasons: []
    }
  },
  computed: {
  },
  components: {
    rocModal
  },
  methods: {
    getAllResons () {
      this.loadTable = true
      settingService.getAllReson(this.currentPage).then(data => {
        this.allReasons = data.data.data
        this.currentPage = data.data.meta.currentPage
        this.per_page = data.data.meta.per_page
        this.total = data.data.meta.total
        this.loadTable = false
      })
    }
  },

  mounted () {
    core.index()
  },
  created () {
    this.getAllResons()
  }
}
</script>
<style scoped>
.colorSpan{
  width: 20px;
  height: 20px;
}
th{
  white-space: pre !important;
}
td{
  white-space: pre !important;
}
</style>
